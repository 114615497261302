<template>
  <div class="fs-14">
    <div class="sidebar__section pp-trans-tx-hash">
      <p class="sidebar__analytics-label">
        TX hash
      </p>
      <p
        class="sidebar__analytics-value flex align-center pp-address-trans"
        :title="selectedElement.value"
      >
        <span class="m-word-break-all">
          {{ selectedElement.value }}
        </span>
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.value)"
        />
      </p>
    </div>
    <div class="stat px-3 pp-trans-all-blocks">
      <div class="stat-tag full">
        <InfoBlock
          label="Total amount"
          :rate="selectedElement.data.inputsAmountPrice ?
            formatByPrice(selectedElement.data.inputsAmountPrice) :
            formatByPrice(selectedElement.totalAmountPrice)"
          rate-time="historical"
          :rate-timestamp="selectedElement.data.inputsAmountPriceTimestamp ?
            selectedElement.data.inputsAmountPriceTimestamp : selectedElement.totalAmountPriceTimestamp"
          :value="formattedTotalAmount"
        />
        <InfoBlock
          class="mr-4 m-mr-0"
          label="Timestamp"
          :loading="isTxInfoLoading"
          :value="transactionInfoDataIO.timestamp ? formatDate(Number(transactionInfoDataIO.timestamp * 1000), 'dd.MM.yyyy HH:mm') : 0"
        />
        <InfoBlock
          label="Block"
          :loading="isTxInfoLoading"
          :value="transactionInfoDataIO.blockHeight && String(transactionInfoDataIO.blockHeight)"
        />
      </div>
    </div>
    <StatusBlock
      v-if="total > 10 || outputsCount > 10"
      class="mb-2 px-3"
      label="Limited number of inputs/outputs is displayed"
    />
    <div class="analytics-tabs analytics-tabs--trans">
      <div
        class="analytics-tabs-item"
        :class="[{'analytics-tabs-item--active': activeTab === 'inputs'}]"
        @click="changeTab('inputs')"
      >
        <div class="flex align-center justify-center">
          Inputs
          {{ displayInputsTotal.length }} / {{ total }}
          <gl-menu-item
            v-popover:tooltip.top="`${inputsTotal} inputs\n${total} unique addresses`"
            icon="actions-info"
            :icon-height="16"
            :icon-width="16"
          />
        </div>
      </div>
      <div
        class="analytics-tabs-item"
        :class="[{'analytics-tabs-item--active': activeTab === 'outputs'}]"
        @click="changeTab('outputs')"
      >
        <div class="flex align-center justify-center">
          Outputs
          {{ displayOutputsTotal.length }} / {{ outputsCount }}
          <gl-menu-item
            v-popover:tooltip.top="`${outputsTotal} outputs\n${outputsCount} unique addresses`"
            icon="actions-info"
            :icon-height="16"
            :icon-width="16"
          />
        </div>
      </div>
      <div
        v-if="featureAccess('REPORT')"
        class="analytics-tabs-item"
        :class="[{'analytics-tabs-item--active': activeTab === 'sof'}]"
        @click="changeTab('sof')"
      >
        <div class="flex align-center justify-center">
          Risk Exposure
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="activeTab === 'sof'"
        class="relative"
      >
        <div v-if="calcLoading">
          <gl-loader class="table__loader" />
        </div>
        <div v-else-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
          <div class="px-3 flex align-baseline space-between">
            <h2 class="sidebar__title mb-2">
              Source of Funds
            </h2>
            <div class="flex fs-14 align-end mr-3">
              Amount
              <div class="px-1 switch__wrap">
                <input
                  id="switch"
                  v-model="track"
                  type="checkbox"
                  @click="trackBy()"
                >
                <label for="switch">Toggle</label>
              </div>
              %
            </div>
          </div>
          <div class="flex align-center justify-center relative">
            <gl-pie
              v-if="!isMobile && !calcLoading"
              ref="pie"
              class="relative aml-detected-list"
              :data-source="uofData.incomingData.typeGrouping"
              :height="470"
              :width="'100%'"
              @mouseout="sectionData = null"
              @move="handlePieHover"
            >
              <template slot="tooltip">
                <transition name="fade">
                  <div
                    v-if="sectionData"
                    id="sourcePie"
                    class="sofPie"
                  >
                    <div
                      v-for="(owner, index) in sectionData.owners"
                      :key="index"
                      :class="{'mb-2': index < sectionData.owners.length - 1}"
                    >
                      <div class="flex align-center bold">
                        <div class="sub-types-amount">
                          <span class="">
                            {{ trackByField === 'share' ? formatShare(owner[trackByField]) : owner.formattedAmount }}
                          </span>
                        </div>
                        <div
                          v-popover:tooltip.top="`${capitalizeFirstLetter(owner.name)}`"
                          class="ml-3 ellipsis sub-types-owner max-w-300"
                        >
                          {{ capitalizeFirstLetter(owner.name) }}
                        </div>
                      </div>
                      <div
                        v-if="trackByField === 'amount'"
                        class="price fs-13"
                      >
                        {{ formatByPrice(owner['amountCur']) }}
                      </div>
                    </div>
                  </div>
                </transition>
              </template>
            </gl-pie>
            <PieDataList
              v-if="isMobile"
              class="m-fullwidth px-3"
              :data="pieData"
              :legend="null"
              title=""
              track-by-label="funds.type"
              track-by-label-support="funds.name"
              :track-value-by-field="trackByField"
            />
            <div class="risk-score-wrap">
              <strong class="risk-score-label">
                Risk Score
              </strong>
              <div
                v-if="totalFunds !== null"
                class="risk-score-value"
                :style="`color: ${findColorByTypeScore(totalFunds)}`"
              >
                {{ formatFunds(totalFunds, false) }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="!uofData.incomingData.typeGrouping.length && !uofData.outgoingData.typeGrouping.length"
            class="empty-users-data text-center"
          >
            Data is empty
          </div>
          <div
            v-else
            class="px-3"
          >
            <div class="flex flex-wrap space-between mt-3">
              <GlSelectButton
                :disabled="calcLoading"
                :options="partBarOptions"
                :value="activePartBar"
                @input="changeActivePartBarValue"
              />
              <GlSelectButton
                v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
                :disabled="calcLoading"
                :options="exposurePartOptions"
                :value="activeExposurePart"
                @input="changeActiveExposure"
              />
            </div>
            <div>
              <div class="pt-2">
                <div class="chart-wrap__side-panel">
                  <gl-scoring-bar-chart
                    :active-part-bar="activePartBar"
                    :basis-percent-graph-width="30"
                    class="transparent-space-right"
                    :data-table="activeBarData"
                    full
                    :loading="calcLoading"
                    :max-share="uofData[`${activeExposurePart.value}Data`].maxShare"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="source__action-block mt-2">
          <div v-if="!calcLoading">
            <div
              v-if="addressRiskPercent > 0 || addressAreUnidentified"
              class="flex align-center mb-1"
            >
              <p class="sidebar__analytics-label__simple mr-2">
                AML RISK DETECTED
              </p>
              <gl-menu-item
                icon="risk-mark"
                :icon-height="16"
                :icon-width="16"
                :label="showRiskMsg ? 'Hide' : 'Show'"
                @click="showRiskMsg = !showRiskMsg"
              />
            </div>
            <div v-if="showRiskMsg">
              <StatusBlock
                v-if="addressRiskPercent > 0"
                class="mb-2"
                :label="txRiskPercentLabel"
              />
              <StatusBlock
                v-if="addressAreUnidentified"
                class="mb-2"
                label="More than 75% of sources for this transaction are unidentified"
              />
            </div>
          </div>
          <div class="gl-form__actions">
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(selectedElement.data.tx_hash)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div
        v-if="activeTab === 'inputs'"
        class="stat"
      >
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          class="pagination-table inputs-outputs-table table__overflow-auto transaction-info-table"
          :data="inputsList"
          hoverable
          :is-row-checkable="(row) => checkStateHandler(row, displayInputsTotal)
            || !Boolean(checkedRows.find(v => v.address === row.address))"
          :mobile-cards="false"
          @check="toggleAddressData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="address"
            label="Address"
          >
            <div
              class="link"
              @click="openInNewTabAddress(props.row.address)"
            >
              {{ trancateString(props.row.address, 8) }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="actions"
            label="Actions"
          >
            <div class="link">
              <AddressTxActions
                :address="props.row.address"
                @add-tx-data="$emit('add-tx-data', $event)"
              />
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            <div class="m-white-space-nowrap">
              {{ props.row.formattedAmount }}
              <span
                v-if="props.row.coinbase && selectedElement.data && selectedElement.data.price || props.row.price"
                class="price ml-1"
              >({{ props.row.coinbase ? formatByPrice(selectedElement.data.price) : formatByPrice(props.row.price) }})
                <gl-icon
                  v-popover:tooltip.top="`${priceMessage('historical', props.row.coinbase ? selectedElement.data.priceTimestamp : props.row.priceTimestamp)}`"
                  class="price-info-icon"
                  :height="11"
                  name="info"
                  :width="11"
                />
              </span>
            </div>
          </o-table-column>
        </o-table>
        <div
          v-if="inputsList.length > 0"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="inputsList.length && totalPages > 1"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
      <div
        v-else-if="activeTab === 'outputs'"
        class="stat"
      >
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="outputsCheckedRows"
          class="pagination-table inputs-outputs-table table__overflow-auto transaction-info-table"
          :data="outputsList"
          hoverable
          :is-row-checkable="(row) => checkStateHandler(row, displayOutputsTotal)
            || !Boolean(outputsCheckedRows.find(v => v.address === row.address))"
          :mobile-cards="false"
          @check="toggleOutputsAddressData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="address"
            label="Address"
          >
            <div
              class="link"
              @click="openInNewTabAddress(props.row.address)"
            >
              {{ trancateString(props.row.address, 10) }}
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="actions"
            label="Actions"
          >
            <div class="link">
              <AddressTxActions
                :address="props.row.address"
              />
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            <div class="m-white-space-nowrap">
              {{ props.row.formattedAmount }}
              <span
                v-if="props.row.price"
                class="price ml-1"
              >({{ formatByPrice(props.row.price) }})
                <gl-icon
                  v-popover:tooltip.top="`${priceMessage('historical', props.row.priceTimestamp)}`"
                  class="price-info-icon"
                  :height="11"
                  name="info"
                  :width="11"
                />
              </span>
            </div>
          </o-table-column>
        </o-table>
        <div
          v-if="outputsList.length > 0"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="outputsList.length && outputsTotalPages > 1"
            class="stat-pagination"
            :current.sync="outputsCurrentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="outputsCount"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex'
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { formatFunds, formatterAmountValue, calcSourcesRiskPercent, getEntityIds, separateSources } from '@/utils/report-data-formatter'
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import { findColorByTypeScore } from "@/utils/cytoskape-ui-rules";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { featureAccess } from "@/utils/accesses";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
// Components
import GlPie from '@/components/charts/gl-pie-chart';
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import StatusBlock from "@/pages/report/components/StatusBlock";
import AddressTxActions from './components/addressTxActions';
import GlIcon from '@/components/gl-icon';
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import GlSelectButton from '@/components/gl-select-button.vue'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Libs
import _ from "lodash";
import uofModel from '@/pages/analytics/analytics-info-blocks/models/uofModel'
import appConfig from '@/utils/appConfig'
import PieDataList from '@/pages/report/components/PieDataList.vue'

export default {
  name: 'TransactionInfo',
  components: {
    GlPie,
    PieDataList,
    GlSelectButton,
    GlScoringBarChart,
    GlLoader,
    InfoBlock,
    GlMenuItem,
    StatusBlock,
    AddressTxActions,
    GlIcon
  },
  mixins: [deviceWidthMixin],
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    searchMode: {
      type: String,
      default: 'tx',
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      showRiskMsg: false,
      pieData: [],
      track: true,
      calcData: [],
      totalFunds: 0,
      sectionData: null,
      calcLoading: true,
      addressUnknownSources: [],
      allDataSource: [],
      trackByField: 'share',
      addressRiskPercent: 0,
      transactionInfoDataIO: {},
      inputsList: [],
      outputsList: [],
      checkedRows: [],
      outputsCheckedRows: [],
      isTxDataLoading: false,
      isTxInfoLoading: false,
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      total: 1,
      outputsCurrentPage: 1,
      outputsPerPage: 20,
      outputsTotalPages: 1,
      outputsCount: 1,
      outputsTotal: 0,
      inputsTotal: 0,
      displayInputsTotal: [],
      displayOutputsTotal: [],
      activeTab: 'inputs',
      uofData: uofModel(),
      activeExposurePart: { name: 'Source of Funds', value: 'incoming' },
      exposurePartOptions: [
        { name: 'Source of Funds', value: 'incoming' },
        { name: 'Use of Funds', value: 'outgoing' },
      ],
      activePartBar: { name: 'Type', value: 'funds.type', key: 'type' },
      partBarOptions: [
        { name: 'Type', value: 'funds.type', key: 'type' },
        { name: 'Entity', value: 'owner', key: 'owner' },
      ],
      step: 10,
      entityIdCache: {},
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    activeBarData() {
      return this.uofData[`${this.activeExposurePart.value}Data`][`${this.activePartBar.key}Grouping`];
    },
    formattedTotalAmount() {
      return this.selectedElement.data.inputsAmount ?
        toComaSeparate(formatBtcAmount(this.selectedElement.data.inputsAmount)) :
        toComaSeparate(formatBtcAmount(this.selectedElement.totalAmount))
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources?.reduce(
        (acc, { share }) => acc + share,
        0,
      )

      return sum * 100 >= 75
    },
    txRiskPercentLabel() {
      if (this.activeExposurePart.value === 'outgoing') {
        return `${formatShare(this.addressRiskPercent)} in transaction are sent to risky destinations`
      }

      return `${formatShare(this.addressRiskPercent)} of funds in this transaction are from risky sources`
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  async mounted() {
    this.formattedInputsCheckedTotal()
    this.loadTransactionDataIO()
  },
  methods: {
    ...mapActions('entity', ['getEntityList']),
    ...mapActions('analytics', [
      'getTransactionInfo',
      'getTransactionInfoIO',
      'getTransactionData',
      'getAddressData',
      'getTransactionRisk',
      'getReportTx',
    ]),
    calcSourcesRiskPercent,
    formatter,
    formatDate,
    formatShare,
    formatFunds,
    featureAccess,
    trancateString,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    findColorByTypeScore,
    formatByPrice,
    priceMessage,
    formatterAmountValue,
    separateSources,
    changeActivePartBarValue(val) {
      this.activePartBar = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    changeActiveExposure(val) {
      this.activeExposurePart = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    toFullReport(tx) {
      const { href } = this.$router.resolve({ name: 'report', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    loadCalculation() {
      this.calcLoading = true

      this.getReportTx({ tx_hash: this.selectedElement.value }).then(({ data, success}) => {
        if (success) {
          this.totalFunds = data?.totalFunds ?? null;

          const { sofSources, uofSources } = this.separateSources(data?.sources)
          const currencies = data?.currencies

          this.calkDataFormatter({ sources: sofSources, currencies} || {}, 'incoming', this.activePartBar)
          this.calkDataFormatter({ sources: uofSources, currencies} || {}, 'outgoing', this.activePartBar)
        }
      }).catch(() => {
        this.calcLoading = false
      }).finally(() => {
        const availableExposurePart = this.exposurePartOptions.find(part => !part.disabled);
        if (this.activeExposurePart.value !== availableExposurePart.value) {
          this.changeActiveExposure(availableExposurePart);
        }
        this.calcLoading = false
      })
    },
    async calkDataFormatter({ sources = [], currencies = {} }, direction, partOptions) {
      if (direction === this.activeExposurePart.value) {
        this.calcLoading = true;

        const uniqueOwners = [...new Set(sources.map(source => source.owner))];
        const entityIdMap = await getEntityIds({
          owners: uniqueOwners,
          entityIdCache: this.entityIdCache,
          getEntityList: this.getEntityList
        });

        sources = sources.map(source => ({
          ...source,
          entityId: entityIdMap[source.owner.toLowerCase()] || null,
        }));

        this.calcLoading = false;
      }

      this.uofData[`${direction}Data`].sources = sources
      this.uofData[`${direction}Data`].currencies = currencies
      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`] = sortingObjects(formatter(sources, partOptions.value), sourcesSortOrderArray)
        .map((source) => {
          const localCurrency = currencies[source.currency]
            ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
            : {}

          return {
            ...source,
            owners: sources
              .filter((v) => v.funds.type === source.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
                formattedAmount: formatBtcAmount(v.amount)
              })),
            funds: {
              ...source.funds,
              default: source.funds.default || false
            },
            key: source.entity,
            name: `${this.trackByField === 'share' ? `${formatShare(sources.length === 1 ? 1 : source.share)}` : this.formatBtcAmount(source[this.trackByField], true, 3)} ${source.funds.type}`,
            isOpen: false,
            value: source.share,
            tooltip: `${source.funds.type} ${this.trackByField === 'share' ? formatShare(source[this.trackByField]) : this.formatBtcAmount(source[this.trackByField], true, 3)}`,
            itemStyle: {color: source.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(source.funds.score)},
            currencyData: localCurrency,
            formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency || this.coinData.label))),
          }
        })

      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].map((item) => {
        item.owners = formatter(item.owners, 'name')
      })

      const opIndex = this.exposurePartOptions.findIndex(({ value }) => value === direction)

      if (opIndex !== -1) {
        this.$set(this.exposurePartOptions[opIndex], 'disabled', Boolean(!sources.length))
      }

      const maxValue = this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].reduce(
        (max, obj) => (obj.share > max ? obj.share : max),
        this.uofData[`${direction}Data`][`${partOptions.key}Grouping`][0]?.share,
      )

      this.uofData[`${direction}Data`].maxShare = Math.ceil((maxValue * 100) / this.step) * this.step

      if (direction === this.activeExposurePart.value) {
        this.addressUnknownSources = this.uofData[`${direction}Data`].sources.filter(source => source.listType === 'Unknown sources')

        this.addressRiskPercent = 0

        this.addressRiskPercent = this.calcSourcesRiskPercent(this.uofData[`${direction}Data`][`${partOptions.key}Grouping`])
      }
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      if ($event.event.event.clientX) {
        this.sectionData = $event.data || null
      }
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.uofData.incomingData.typeGrouping = this.uofData.incomingData.typeGrouping.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)} ${item.funds.type}`,
        tooltip: `${item.funds.type} ${this.trackByField === 'share' ? formatShare(item.share) : this.formatBtcAmount(item[this.trackByField], true, 3)}`,
      }))
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address } })
      window.open(href, '_blank')
    },
    formattedInputsCheckedTotal() {
      const a = []
      const b = []
      this.displayInputsTotal = []
      this.displayOutputsTotal = []
      this.cytoscape.cy.edges(`[txHash="${this.selectedElement.value}"]`).forEach(v => {
        a.push(v.data('source'))
      })

      this.cytoscape.cy.edges(`[txHash="${this.selectedElement.value}"]`).forEach(v => {
        b.push(v.data('target'))
      })

      this.displayInputsTotal = a.filter((v, i) => a.indexOf(v) === i)
      this.displayOutputsTotal = b.filter((v, i) => b.indexOf(v) === i)
    },
    async updateData() {
      this.uofData = uofModel()
      this.currentPage = 1
      this.outputsCurrentPage = 1
      await this.loadTransactionDataIO()
      await this.formattedInputsCheckedTotal()
      await this.loadCalculation()
    },
    checkStateHandler(rowData, displayData) {
      return !((displayData.length === 1 && displayData.findIndex(v => v === rowData.address) !== -1)
        || this.cytoscape.isCircleTx(rowData.address)
        || this.cytoscape.nodeHasManyTxsHash(rowData.address))
    },
    checkingRows() {
      this.inputsList.map((item) => {
        if (this.cytoscape.searchElements(item.address, false, false).length > 0
          && this.cytoscape.checkConnectAddressByHash(item.address, this.selectedElement.value)) {
          this.checkedRows.push(item)
        }
      })

      this.outputsList.map((item) => {
        if (this.cytoscape.searchElements(item.address, false, false).length > 0
            && this.cytoscape.checkConnectAddressByHash(item.address, this.selectedElement.value)) {
          this.outputsCheckedRows.push(item)
        }
      })
    },
    changeTab(tab) {
      this.currentPage = 1
      this.outputsCurrentPage = 1
      this.activeTab = tab

      if (tab === 'sof') {
        this.loadCalculation()
      }

      this.loadTransactionDataIO()
      this.$nextTick(() => {
        document.querySelector('.analytics-tabs').scrollLeft = document.querySelector('.analytics-tabs-item--active').offsetLeft - 10
      });
    },
    toggleOutputsAddressData(val) {
      const selectedEl = val[val.length - 1]
      // this.outputsCheckedRows = this.outputsCheckedRows.filter((v,i,a)=>a.findIndex(t=>(t.address===v.address))===i)
      const diffItem = _.difference(this.outputsCheckedRows, val)
      const isAdd = val.length > this.outputsCheckedRows.length
      const supportTxData = {
        ...this.transactionInfoDataIO,
        inputsAmount: this.transactionInfoDataIO.inputsAmount,
        outputsAmount: this.transactionInfoDataIO.outputsAmount,
        tx_hash: this.transactionInfoDataIO.tx_hash
      }

      if (isAdd) {
        this.cytoscape.addOutput(selectedEl, this.selectedElement.value, supportTxData)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.removeInputOrOutput(ele.address)
          })
        }
      }

      this.cytoscape.checkFulledDisplayingTX(this.selectedElement.value)

      this.formattedInputsCheckedTotal()
    },
    toggleAddressData(val) {
      const selectedEl = val[val.length - 1]
      const diffItem = _.difference(this.checkedRows, val)
      const isAdd = val.length > this.checkedRows.length
      const supportTxData = {
        ...this.transactionInfoDataIO,
        inputsAmount: this.transactionInfoDataIO.inputsAmount,
        outputsAmount: this.transactionInfoDataIO.outputsAmount,
        tx_hash: this.transactionInfoDataIO.tx_hash
      }

      if (isAdd) {
          this.cytoscape.addInput(selectedEl, this.selectedElement.value, supportTxData)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.removeInputOrOutput(ele.address)
          })
        }

        this.cytoscape.checkFulledDisplayingTX(this.selectedElement.value)
      }

      this.checkingRows()

      this.formattedInputsCheckedTotal()
    },
    pageChange(event) {
      if (this.activeTab === 'inputs') {
        this.currentPage = event
      } else {
        this.outputsCurrentPage = event
      }
      this.loadTransactionDataIO()
    },
    formattedSendData() {
      if (this.activeTab === 'inputs') {
        return {
          txHash: this.selectedElement.value,
          inputsPerPage: this.perPage,
          inputsSkip: (this.currentPage - 1) * this.perPage,
        }
      } else {
        return {
          txHash: this.selectedElement.value,
          outputsPerPage: this.outputsPerPage,
          outputsSkip: (this.outputsCurrentPage - 1) * this.outputsPerPage,
        }
      }
    },
    loadTransactionDataIO() {
      this.isTxInfoLoading = true
      if(!this.inputsList.length) this.isTxDataLoading = true
      const sendParams = this.formattedSendData()
      this.getTransactionInfo(sendParams)
          .then(({ data }) => {
            this.inputsList = []
            this.outputsList = []
            this.transactionInfoDataIO = data
            this.inputsList = data.inputs.map(el => {
              return {
                ...el,
                formattedAmount: el.coinbase ? toComaSeparate(formatBtcAmount(this.selectedElement.data.inputsAmount)) : toComaSeparate(formatBtcAmount(el.amount))
              }
            })
            this.total = data.inputsCount
            this.totalPages = Math.ceil(this.total / this.perPage)
            this.outputsList = data.outputs.map(el => {
              return {
                ...el,
                formattedAmount: this.toComaSeparate(this.formatBtcAmount(el.amount))
              }
            })
            this.outputsCount = data.outputsCount
            this.outputsTotal = data.totalOutputs
            this.inputsTotal = data.totalInputs
            this.outputsTotalPages = Math.ceil(this.outputsCount / this.outputsPerPage)
            this.checkingRows()
          })
          .finally(() => {
            this.isTxInfoLoading = false
            this.isTxDataLoading = false
          })
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.source__action-block {
  position: fixed;
  bottom: 0;
  width: 640px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 12px 24px 24px;
  background: var(--white);
}

.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.analytics-tabs {
  position: relative;
  justify-content: space-around;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 155px;
}

.risk-score-label {
  font-size: 14px;
}

.sub-types-owner {
  text-align: left;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.sofPie {
  width: max-content;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  transform: translateX(-100%);
}

.sub-types-amount {
  white-space: nowrap;
  display: block;
  min-width: 140px;
}

.switch__wrap input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch__wrap label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 10px;
  background: #3030D628;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch__wrap label:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 16px;
  height: 16px;
  background: var(--reflex-bluet);
  border-radius: 90px;
  transition: 0.3s;
}

.switch__wrap input:checked + label {
  background: #3030D628;
}

.switch__wrap input:checked + label:after {
  left: 100%;
  transform: translateX(-100%);
}

.switch__wrap label:active:after {
  width: 50px;
}

.switch__wrap {
  margin-bottom: 3px;
}

@keyframes skeleton-animation
{

  0%
  {
    right: 100%;
  }

  50%
  {
    right: 0;
  }

  100%
  {
    right: 100%;
  }

}

@media (max-width: 767px) {
  .source__action-block {
    position: static;
    width: 100%;
  }
  .analytics-tabs {
    justify-content: unset;
  }
  .stat-item {
    width: 50%;
  }
  .risk-score-wrap {
    top: 130px;
  }
}
@media (max-width: 360px) {
  .stat-item {
    width: 100%;
  }
}
</style>
